<template>
<v-app app>
    <!-- Top menu -->
    <v-app-bar app height="70" class="bgtop" width="100%">
        <v-col color="green" cols="3">
            <v-img alt="EPS Logo" src="./assets/logo_miepsplus.png" transition="scale-transition" width="60"></v-img>
        </v-col>
        <v-col cols="9">
            <v-row justify="center" align="center">
                <h1 class="encabezado pr-6">{{$store.state.title}}</h1>
            </v-row>
            <v-row justify="center" align="center">
                <span class="pl" style="color:white;font-size: 12px;">{{$store.state.w}}</span>
            </v-row>
        </v-col>
    </v-app-bar>
    <!-- Main app window -->
    <v-main class="bg" :style="backgroundStyle">
        <v-row align="center" justify="center" class="ma-0 pa-0">
            <v-col cols="12" md="4" class="ma-0 pa-0">
                <div v-if="app">
                    <LoginSignup v-if="!$store.state.loggedin"></LoginSignup>
                    <div v-if="$store.state.loggedin">
                        <MiGarage v-if="$store.state.loggedin && $store.state.menu_inferior == 0 && !newuser"></MiGarage>
                        <MiAyuda v-if="$store.state.loggedin && $store.state.menu_inferior == 2  && !newuser"></MiAyuda>
                        <MisEventos v-if="$store.state.loggedin && $store.state.menu_inferior == 1  && !newuser"></MisEventos>
                        <MiCuenta v-if="$store.state.loggedin && $store.state.menu_inferior == 4  && !newuser"></MiCuenta>
                        <MisCupones v-if="$store.state.loggedin && $store.state.menu_inferior == 3  && !newuser"></MisCupones>
                        <SignUp v-if="newuser"></SignUp>
                    </div>
                </div>
                <div v-if="!app">
                    <HomePage />
                </div>

            </v-col>
        </v-row>
        <!-- Alert-->
        <v-snackbar color="green lighten-5" timeout="5000" max-width="500px" v-model="alert_vehicles"
        class="pa-8">
        <h3 class="black--text">Tus vehículos se han actualizado correctamente. Puedes ir a Mi Garage para verlos.</h3>
        
    </v-snackbar>
        
        <v-dialog
            v-model="isChatOpen"
            max-width="400px"
            transition="dialog-bottom-transition"
            persistent
        >
          
        </v-dialog>
    </v-main>
    <!-- Bottom menu -->
    <v-bottom-navigation app v-model="$store.state.menu_inferior" v-if="$store.state.loggedin" class="pt-2">
        <v-btn value="4" class="menu_btn" color="white">
            <v-img v-if="$store.state.menu_inferior == 4" src="./assets/MI-CUENTA-ON.png" :width="btnwidth" style='z-index:1;'></v-img>
            <v-img v-else src="./assets/MI-CUENTA-OFF.png" :width="btnwidth" color="green"></v-img>
        </v-btn>
        <v-btn value="0" class="menu_btn">
            <v-img v-if="$store.state.menu_inferior == 0" src="./assets/MI-GARAGE-ON.png" :width="btnwidth"></v-img>
            <v-img v-else src="./assets/MI-GARAGE-OFF.png" :width="btnwidth"></v-img>
        </v-btn>

        <v-btn value="1" class="menu_btn" color="white">
            <v-img v-if="$store.state.menu_inferior == 1" src="./assets/MI-AGENDA-ON.png" :width="btnwidth"></v-img>
            <v-img v-else src="./assets/MI-AGENDA-OFF.png" :width="btnwidth"></v-img>
        </v-btn>
        <v-btn value="2" class="menu_btn" color="white">
            <v-img v-if="$store.state.menu_inferior == 2" src="./assets/MI-AYUDA-ON.png" :width="btnwidth"></v-img>
            <v-img v-else src="./assets/MI-AYUDA-OFF.png" :width="btnwidth"></v-img>
        </v-btn>
        <v-btn value="3" class="menu_btn" color="white">

            <v-badge color="green" :content="$store.state.coupons_total | MXN_no_cents" class="pa-0 mr-0" offset-x="30" offset-y="6" style='z-index:2;'>
                <v-img v-if="$store.state.menu_inferior == 3" src="./assets/MIS-CUPONES-ON.png" :width="btnwidth"></v-img>
                <v-img v-else src="./assets/MIS-CUPONES-OFF.png" :width="btnwidth" color="green"></v-img>
            </v-badge>

        </v-btn>

        <v-btn value="4" class="menu_btn" @click="logout" color="white">
            <v-img src="./assets/SALIR.png" :width="btnwidth"></v-img>
        </v-btn>

    </v-bottom-navigation>
    <!-- Account confirmed message -->
    <v-overlay v-model="show_confirm_overlay" opacity="100" color="green">
        <v-col align="center">
            <v-row align="center" justify="center" class="pb-6 pt-6">
                <v-avatar size="90" >
                    <v-img
                      src="./assets/logo_miepsplus.png"
                      alt="John"
                    ></v-img>
                  </v-avatar>
            </v-row>
            <v-row justify="center" class="ma-8" align="center">
                <h2>Tu cuenta se ha confirmada, ya puedes ingresar desde la aplicación EPS+ <br><br>
                Haz click para descargar:</h2>
            </v-row>
            <v-row justify="center" class="ma-4" align="center">
                <v-col cols="auto" class="pa-2">
                    <a href="https://apps.apple.com/us/app/eps-plus/id6479049751">
                        <v-img src="./assets/appstore.svg" max-width="150"></v-img>
                    </a>
                </v-col>
                <v-col cols="auto" class="pa-2">
                    <a href="https://play.google.com/store/apps/details?id=prosperus.network.mieps">
                        <v-img src="./assets/playstore.png" max-width="140"></v-img>
                    </a>
                </v-col>
            </v-row>
        </v-col>
    </v-overlay>
    <!-- Password reset confirmaation -->
    <v-overlay v-model="show_reset_password_confirmation" opacity="100" color="green">
        <v-col align="center">
            <v-row justify="center" class="ma-8" align="center">
                <h2>Tu contraseña ha sido actualizada, ya puedes ingresar desde tu aplicación EPS+</h2>
            </v-row>
        </v-col>
    </v-overlay>
    <!-- Reset password form -->
    <v-dialog v-model="show_reset_password" opacity=".9">
        <v-row justify="center" class="mb-4">
            <v-img alt="MiEPS Logo" src="./assets/logo_miepsplus.png" transition="scale-transition" max-width="100" />
        </v-row>
        <v-form>
            <v-card dense>
                <v-card-title>
                    EPS+
                </v-card-title>
                <v-card-title>
                    Escribe una contraseña nueva:
                </v-card-title>
                <v-card-text>
                    Llena los datos para cambiar tu contraseña en EPS+
                </v-card-text>
                <v-card-text>
                    <v-text-field v-model="password" name="password" type="password" label="Contraseña nueva" id="id" prepend-icon="mdi-lock"></v-text-field>
                    <v-text-field v-model="confirm_password" name="password" type="password" label="Confirma contraseña nueva" id="id" prepend-icon="mdi-lock"></v-text-field>
                </v-card-text>
                <v-row justify="center" class="mb-2">{{ reset_message }}</v-row>
                <v-card-actions>

                    <v-btn @click="reset_password()" :loading="reseting" block class="eps_green" dark>Cambiar contraseña...</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</v-app>
</template>

<script>
import LoginSignup from './components/LoginSignup.vue';
import MiGarage from './components/MiGarage.vue';
import MiAyuda from './components/MiAyuda.vue';
import MisEventos from './components/MisEventos.vue';
import MiCuenta from './components/MiCuenta.vue';
import HomePage from "./components/HomePage.vue"
import MisCupones from "./components/MisCupones.vue"
import SignUp from './components/SignUp.vue';
import axios from 'axios'
import { io } from 'socket.io-client';

export default {
    name: 'App',

    components: {
        LoginSignup,
        MiGarage,
        MiAyuda,
        MisEventos,
        MiCuenta,
        MisCupones,
        SignUp,
        HomePage
        },

    data: () => ({
        alert_vehicles:false,
        newuser:false,
        alert_title: "",
        alert_message: "",
        alert: false,
        btnwidth: 55,
        show_reset_password_confirmation: false,
        password: "",
        reset_message: "",
        confirm_password: "",
        app: false,
        show_reset_password: false,
        show_confirm_overlay: false,
        reset_password_token: "",
        reseting: false,
        backgroundImage: require('./assets/back01.png'), // Using the image path you provided
        isChatOpen: false,
        socket: null,
    }),
    methods: {
        async logout() {
            try {
                this.$store.state.loggedin = false;
                if (this.socket) {
                    await this.socket.disconnect();
                    this.socket = null;
                }
                localStorage.clear();
                this.$store.state.menu_inferior = 0;
            } catch (error) {
                console.error('Error during logout:', error);
            }
        },
        async confirm_account(token) {
            let confirmed = await axios.post(process.env.VUE_APP_ROOT_API + '/api/clients/confirm_account', {
                token: token,
            })
            if (confirmed.data.status == "ok") {
                this.show_confirm_overlay = true
            }
        },
        async reset_password() {
            this.reset_message = ""
            this.reseting = true
            if (this.password == "" || this.confirm_password == "") {
                this.reset_message = "Por favor escribe y confirma tu contraseña"
                this.reseting = false
            } else if (this.password != this.confirm_password) {
                this.reset_message = "Las contraseñasa no coinciden"
                this.reseting = false

            } else {
                try {
                    let response = await axios.post(process.env.VUE_APP_ROOT_API + '/api/clients/reset_password', {
                        reset_password_token: this.reset_password_token,
                        password: this.password
                    })
                    
                    if (response.data.status == "ok") {
                        this.alert_title = "El password se cambió con éxito"
                        this.alert_title = "Cambiaste tu contraseña"
                        this.show_reset_password = false
                        this.show_reset_password_confirmation = true
                        this.reseting = false
                    }
                } catch (error) {
                    console.log(error)

                }
            }
        },
        initializeSocket() {
            try {
                if (!this.$store.state.user || !this.$store.state.user.id) {
                    console.error('User not properly authenticated for socket connection');
                    return;
                }

                this.socket = io(process.env.VUE_APP_ROOT_API, {
                    query: {
                        userId: this.$store.state.user.id
                    },
                    reconnection: true,
                    reconnectionAttempts: 5,
                    reconnectionDelay: 1000,
                    transports: ['websocket', 'polling']
                });
                
                this.socket.on('connect_error', (error) => {
                    console.error('Socket connection error:', error);
                });

                this.socket.on('connect', () => {
                    console.log('Socket connected successfully with id:' + this.$store.state.user.id);
                    this.socket.emit('identify', this.$store.state.user.id);
                });

                this.socket.on('disconnect', () => {
                    console.log('Socket disconnected');
                });

                this.socket.on('vehicle_updated', (data) => {
                    console.log("Update")
                    if (data) {
                        window.location.reload();
                        this.$store.state.menu_inferior=0
                    } else {
                        console.warn('Invalid vehicle update data received:', data);
                    }
                });

                // Keep existing notification handler
                this.socket.on('notification', (data) => {
                    console.log('Received notification:', data);
                });

                // Add user-specific vehicle update listener
                this.socket.on(`vehicle_updated_${this.$store.state.user.id}`, (data) => {
                    console.log('Vehicle update received for specific user:', data);
                    if (data && data.success) {
                        window.location.reload();
                        this.alert_vehicles=true
                    } else {
                        console.warn('Invalid vehicle update data received:', data);
                    }
                });
            } catch (error) {
                console.error('Failed to initialize socket:', error);
            }
        },
    },
    async created() {
        try {
            if (localStorage.getItem("loggedin")) {
                // Ensure user data is loaded before socket initialization
                this.$store.state.user = JSON.parse(localStorage.getItem("user"));
                if (this.$store.state.user) {
                    this.initializeSocket();
                } else {
                    console.warn('User data not found in localStorage');
                }
            }
            const queryString = window.location.search
            const urlParams = new URLSearchParams(queryString);
            const origin = urlParams.get('origin')
            const cupon = urlParams.get('cupon')
            const token = urlParams.get('token')
            const newuser = urlParams.get('newuser')
            const reset_password = urlParams.get('reset_password')
            if(newuser==1){
                this.newuser=true
            }
            if (cupon) {
                this.$store.state.cupon = cupon
                this.newuser=true
            }
            if (reset_password) {
                this.reset_password_token = reset_password
                this.show_reset_password = true
            }

            if (token) {
                await this.confirm_account(token)
            }
            if (origin == "app") {
                if (localStorage.getItem("loggedin")) {
                    this.initializeSocket();
                    this.$store.state.user = JSON.parse(localStorage.getItem("user"))
                    this.$store.state.user_vehicles = JSON.parse(localStorage.getItem("vehicles"))
                    this.$store.state.loggedin = true
                }
                this.app = true
            } else {
                this.app = false
            }
        } catch (error) {
            console.error('Error in created hook:', error);
        }
    },
    computed: {
        sum() {
            const sum = this.$store.state.coupons.reduce((accumulator, object) => {
                return accumulator + object.amount;
            }, 0);
            console.log(sum)
            return sum
        },
        backgroundStyle() {
            return {
                backgroundImage: `url(${this.backgroundImage})`,
                backgroundSize: '100% 100%',
                backgroundPosition: 'center',
                backgroundRepeat: 'repeat',
                minHeight: '100vh'
            }
        }
    },
    beforeDestroy() {
        // Cleanup socket connection when component is destroyed
        if (this.socket) {
            this.socket.disconnect();
        }
    },
};
</script>

<style>
body,
html {
    overflow-x: hidden;
    height: 100%;
    margin: 0;
}



.eps_green {
    color: black !important;
    background-color: rgb(145, 189, 58) !important;
    margin: 4px !important;
}
.eps_green_front {
    color: rgb(145, 189, 58) !important;
    background-color: rgb(255, 255, 255, 0) !important;
    margin: 4px !important;
}

.eps_amber {
    color: rgb(0, 0, 0) !important;
    background-color: rgb(244, 195, 0) !important;
    margin: 4px !important;
}

.eps_black {
    color: black !important;
    background-color: rgb(0, 0, 0) !important;
    margin: 4px !important;
}

.eps_red {
    color: rgb(254, 254, 254) !important;
    background-color: rgb(237, 28, 36) !important;
    margin: 4px !important;
}

.boton_inferior {
    font-size: 9px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
}

.encabezado {
    color: rgb(255, 255, 255);
    font-family: 'Arial Black (sans-serif)', Arial, sans-serif;
    font-size: 1.5em;
}

.menu_inferior {
    padding-block: 4;
}

.bgtop {
    background: url('./assets/topbar.png') center center;
    width: 100%;
}

.color {
    color: blue;
    background-color: blue;
}

.menu_btn {
    min-width: 0px !important;
    background-color: transparent !important;
    color: transparent !important;

}

.no-background-hover {
    background-color: transparent !important;
}

.chat-trigger {
    margin-bottom: 80px !important;
    margin-right: 16px !important;
}
</style>

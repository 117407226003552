<template>
  <div class="iframe-container">
    <iframe id="ifrm" src="https://epsapp.prosperus.network/ayuda/index.php/chat/start" width="100%" height="100%"></iframe>
  </div>
</template>
<script>
export default {
    name: "MiAyuda",
    data:()=>({

    }),
    created(){
        this.$store.state.title="Ayuda"
     }
}
</script>

<style>
.iframe-container {
    background-color: rgb(255, 255, 255, .7);
  height: 90vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

iframe {
  border: none;
  display: block;
  height: 100%;
  width: 100%;
}
</style>

